import { useState } from 'react';
import { useRouter } from 'next/router';
import { TextInput, Button } from '@mantine/core';
import { IconArrowRightCircle } from '@tabler/icons'
import dynamic from 'next/dynamic';
const AuthLayout = dynamic(() => import('@/components/AuthLayout'));
import { motion, AnimatePresence } from "framer-motion"
import { notifications } from '@mantine/notifications';
import { IconCheck, IconExclamationMark, IconX } from '@tabler/icons'

export default function Index() {
  const router = useRouter()
  const [animate, setAnimate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pharmacyCode, setPharmacyCode] = useState('')

  const Navigate = async () => {
    //TODO: Check if pharmacy code exists?
    if(pharmacyCode === "") {
      notifications.show({
        title: 'Warning',
        message: 'Enter your pharmacy code',
        icon: <IconExclamationMark size="1rem" />,
        autoClose: 2000,
        color: 'yellow',
      })
      return
    }
    setLoading(true)
    router.push(`/auth/${pharmacyCode}`)
    setLoading(false)
  }

  return (
    <AuthLayout>
      <motion.div
        key="pharmacy-code"
        initial={{ opacity: 0, x: 40 }}
        animate={{ opacity: 1, x: 0}}
        exit={{ opacity: 0, x: -40 }}
        transition={{ duration: 0.6, stiffness: 300 }}
        className="h-72 flex flex-col justify-center"
      >
        <TextInput 
          label="Pharmacy Code" 
          name='pharmacyCode' 
          placeholder="yasmeen1" 
          value={pharmacyCode} 
          onChange={e => setPharmacyCode(e.target.value)} 
          required 
        />
        <Button fullWidth mt="xl" className='bg-blue-700' onClick={Navigate} loading={loading} onMouseEnter={() => setAnimate(true)} onMouseLeave={() => setAnimate(false)}>
          <span
            className='flex items-center justify-center w-16'
          >
            <p className='pr-4'>Enter</p>
            <AnimatePresence>
            {animate && (
              <motion.div animate={{ x: -10 }} transition={{ duration: 0.2, stiffness: 300 }} exit={{ x: 0 }}>
                <IconArrowRightCircle size={14}/>
              </motion.div>
            )}
            </AnimatePresence>
          </span>
        </Button>
      </motion.div>
    </AuthLayout>
  );
}